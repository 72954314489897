import {formatDateTime} from '../utils/dates';
import NotificationMixin from './NotificationMixin';
import _isEqual from "lodash/isEqual";

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      options: {
        sortBy: [],
        descending: false,
        page: 1,
        itemsPerPage: 15,
      },
      filters: {
        offer: {customer: {}}
      }
    };
  },

  watch: {
    deletedItem(item) {
      this.showMessage(`${item['@id']} deleted.`);
    },

    error(message) {
      message && this.showError(message);
    },

    items() {
      this.options.totalItems = this.totalItems;
    }
  },

  methods: {
    onUpdateOptions(props, force = false) {
      const optionsStateDiff = this.state ? Object.entries(this.state.options).reduce(
        (diff, [key, value]) => (_isEqual(props[key], value) ? diff : { ...diff, [key]: value }),
        {}
      ) : false;
      if(optionsStateDiff !== false && (Object.keys(optionsStateDiff).length === 0 && force === false)){
        return;
      }

      const {page, itemsPerPage, sortBy, sortDesc, descending, totalItems} = props;
      let params = {
        ...this.filters
      };
      var resetList = false;
      if (itemsPerPage > 0) {
        params = {...params, itemsPerPage, page};
        resetList = true;
      }
      if (sortBy.length > 0) {
        params[`order[${sortBy[0]}]`] = sortDesc[0] ? 'desc' : 'asc';
        resetList = true;
      }
      this.resetList = resetList;

      this.getPage(params).then(() => {
        this.options.sortBy = sortBy;
        this.options.descending = descending;
        this.options.itemsPerPage = itemsPerPage;
        this.options.totalItems = totalItems;
      });
    },

    onSendFilter() {
      this.resetList = true;
      this.onUpdateOptions(this.options, true);
    },

    resetFilter() {
      this.filters = {offer: {customer: {}}};
    },

    addHandler() {
      this.$router.push({name: `${this.$options.servicePrefix}Create`});
    },

    showHandler(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Show`,
        params: {id: item['@id']}
      });
    },

    editHandler(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Update`,
        params: {id: item['@id']}
      });
    },

    deleteHandler(item) {
      this.deleteItem(item).then(() => this.onUpdateOptions(this.options, true));
    },
    formatDateTime
  }
};
