import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingContacts: [],
    };
  },
  computed: {
    ...mapFields("contact", {
      contactIds: "allIds",
      contactIdsCached: "cacheAllIds",
    }),
    ...mapGetters("contact", {
      findContact: "find",
    }),
  },
  methods: {
    ...mapActions("contact", {
      retrieveContact: "load",
      deleteContact: 'del',
      resetAllContactData: 'resetAllData',
    }),
    resolveContact(itemId) {
      if (!this.contactIdsCached.includes(itemId) && !this.loadingContacts.includes(itemId)) {
        this.loadingContacts.push(itemId);
        this.retrieveContact(decodeURIComponent(itemId));
      }
      return this.findContact(itemId);
    },
    resolveContacts(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveContact(itemId);
      }).filter(e => e);
    },
    getContactItem(contactIri, type) {
      if (contactIri !== null) {
        let contact = this.resolveContact(contactIri);
        if (contact) {
          return contact[type];
        }
      }
      return '';
    },
    clearContactData() {
      this.loadingContacts = [];
      this.resetAllContactData();
    }
  }
};
