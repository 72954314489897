<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
        <v-checkbox :label="$t('isSubscribed')" v-model="item.__Fis_isSubscribed_1"
          @change="uncheck(item, 'is')" />
      </v-col>
      </v-row>
     
    </v-container>
  </template>
  
  <script>
  export default {
    name: "NewsletterSubscriptionFilter",
    props: {
      values: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {};
    },
    mounted() {},
  
    computed: {
      // eslint-disable-next-line
      item() {
        return this.initialValues || this.values;
      },
    },
    methods: {
        uncheck(item, el) {
      if (item.__Fis_isSubscribed_1 === true && el === 'is') {
        item.__Fis_isSubscribed_0 = false
      }
      if (item.__Fis_isSubscribed_0 === true && el === 'isNot') {
        item.__Fis_isSubscribed_1 = false
      }
    }
  }
  };
  </script>
  