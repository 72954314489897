import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      loadingAddresses: [],
    };
  },
  computed: {
    ...mapFields("address", {
      addressIds: "allIds",
      addressIdsCached: "cacheAllIds",
    }),
    ...mapGetters("address", {
      findAddress: "find",
    }),
  },
  methods: {
    ...mapActions("address", {
      retrieveAddress: "load",
      resetAllAddressData: 'resetAllData'
    }),
    resolveAddress(itemId) {
      if (!this.isAddressLoaded(itemId) && !this.loadingAddresses.includes(itemId)) {
        this.loadingAddresses.push(itemId);
        this.retrieveAddress(decodeURIComponent(itemId));
      }
      return this.findAddress(itemId);
    },
    resolveAddresses(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveAddress(itemId);
      }).filter(e => e);
    },
    getAddressItem(addressIri, type) {
      if (addressIri !== null) {
        let address = this.resolveAddress(addressIri);
        if (address) {
          return address[type];
        }
      }
      return '';
    },
    isAddressLoaded(itemId) {
      return this.addressIdsCached.includes(itemId);
    },
    isAddressSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isAddressLoaded(itemId));
    },
    resolveAddressAsync(itemId) {
      const poll = resolve => {
        if (this.isAddressLoaded((itemId))) {
          resolve(this.resolveAddress(itemId));
        } else {
          this.resolveAddress(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveAddressesAsync(itemIds) {
      const poll = resolve => {
        if (this.isAddressSetLoaded((itemIds))) {
          resolve(this.resolveAddresses(itemIds));
        } else {
          this.resolveAddresses(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    clearAddressData() {
      this.loadingAddresses = [];
      this.resetAllAddressData();
    }
  }
};
