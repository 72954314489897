<template>
  <v-expansion-panels v-model="filterModel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('Filters') }}
        <template v-slot:actions>
          <v-icon large>mdi-filter-variant</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="filter"></slot>
        <v-btn @click="handleFilter" color="primary">{{ $t('Filter') }}</v-btn>
        <v-btn @click="handleReset" class="ml-2 mainAccent lighten-3" dark>{{ $t('Reset') }}</v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'DataFilter',
  props: {
    handleReset: {
      type: Function,
      required: true
    },
    handleFilter: {
      type: Function,
      required: true
    },
    initFilterState: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      filterModel: this.initFilterState ? 0 : null,
    };
  },
};
</script>
