<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData"
            :title="$t('NewsletterSubscriptions')" />

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <NewsletterSubscriptionFilter :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>

          <br />
          <v-btn outlined @click="downloadCsv()">
            <v-icon>mdi-download</v-icon>
            <span>{{ $t("DownloadCsv") }}</span>
          </v-btn>
          <br />
          <v-data-table :headers="headers" :items="items" :items-per-page.sync="options.itemsPerPage" :loading="isLoading"
            :loading-text="$t('Loading...')" :options.sync="options" :server-items-length="totalItems"
            @update:options="onUpdateOptions" class="elevation-1" item-key="@id" v-model="selected">
            <template v-slot:item.createdAt="{ item }">
              {{ formatDateTime(item["createdAt"], "L") }}
            </template>
            <template v-slot:item.verificationTokenExpiresAt="{ item }">
              {{ formatDateTime(item["verificationTokenExpiresAt"], "L") }}
            </template>
            <template v-slot:item.customer="{ item }">
              <v-btn plain color="primary dark" @click="openCustomerDialog(resolveCustomer(item['customer']))">
                {{ getCustomerItem(item["customer"], "customerNumber") }}
              </v-btn>
            </template>
            <template v-slot:item.isSubscribed="{ item }">
              <v-icon small v-if="item.isSubscribed">mdi-check-circle</v-icon>
              <v-icon small v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell :handle-delete="() => deleteHandler(props.item)" slot="item.action" slot-scope="props">
            </ActionCell>
          </v-data-table>
          <CustomerInfoDialog
            v-model="showCustomerDialog"
            :customer="customerDialogItem"
            :key="customerDialogItem.id"
            @close="showCustomerDialog = false"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import NewsletterSubscriptionFilter from "../../components/newsletterSubscription/Filter";
import DocumentMixin from "@/mixins/DocumentMixin";
import CustomerMixin from "@/mixins/CustomerMixin";
import CustomerInfoDialog from "@/components/customer/CustomerInfoDialog";

export default {
  name: "NewsletterSubscriptionList",
  servicePrefix: "NewsletterSubscription",
  mixins: [ListMixin, DocumentMixin, CustomerMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    NewsletterSubscriptionFilter,
    CustomerInfoDialog
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("email"), value: "email" },
        { text: this.$i18n.t("Customer"), value: "customer", align: "center", },
        { text: this.$i18n.t("isSubscribed"), value: "isSubscribed", align: "center", },
        { text: this.$i18n.t("createdAt"), value: "createdAt" },
        {
          text: this.$i18n.t("optinExpiresAt"),
          value: "verificationTokenExpiresAt",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      selected: [],
      filters: {
      },
      showCustomerDialog: false,
      customerDialogItem: {},
    };
  },
  computed: {
    ...mapGetters("newsletterSubscription", {
      items: "list",
    }),
    ...mapFields("newsletterSubscription", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("newsletterSubscription", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearNewsletterSubscriptionData: "resetAllData",
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    refreshData() {
      this.clearNewsletterSubscriptionData();
      this.onSendFilter();
    },
    downloadCsv() {
      this.downloadDocument(
        "newsletter_subscriptions/generateCsv",
        "Newsletter_Abonnenten.csv",
        true
      );
    },
    openCustomerDialog(customer) {
      this.customerDialogItem = customer;
      this.showCustomerDialog = true;
    },
  },
};
</script>
