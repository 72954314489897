<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="380px"
    :key="customer['@id']"
  >
    <v-card>
      <v-card-title>Kunde: {{ customer.customerNumber }}</v-card-title>
      <v-card-text v-if="profile">
        <div class="text-h6" v-if="profile.company">{{ profile.company }}</div>
        <div class="text-subtitle-1">
          {{ profile.salutation }} {{ profile.title }} {{ profile.firstName }} {{ profile.lastName }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="showDialog = false">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: "CustomerInfoDialog",
  mixins: [ProfileMixin],
  props: {
    value: Boolean,
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    profile() {
      if (this.customer['@id'] && this.customer.profile) {
        return this.resolveProfile(this.customer.profile);
      }
      return null;
    }
  },
};
</script>
